import Itinerary from '@/itineraries/models/itinerary'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// import itinerariesService from '@/itineraries/services/itineraries-service'

@Component
export default class ItinerarySelection extends Vue {

  @Prop({ required: true })
  private value!: string

  @Prop({ default: () => [] })
  private itineraries!: Itinerary[]

  @Prop({ default: false })
  private loading!: boolean

  @Prop({ default: false })
  private disabled!: boolean

  private itineraryId: string = this.value

  get defaultItinerary() {
    return this.itineraries.find((i) => i.isDefault)
  }

  @Watch('value')
  private watchValue(val: string, old: string) {
    if (!val) {
      this.setDefaultItinerary()
    } else if (val !== old) {
      this.itineraryId = val
    }
  }

  @Watch('itineraryId')
  private watchItineraryId(val: string, old: string) {
    if (val !== old) {
      this.$emit('input', val)
    }
  }

  // @Watch('itineraries', { deep: true })
  // private itinerariesChanged(val: Itinerary[], old: Itinerary[]) {
  //   if (val !== old) {
  //     this.setDefaultItinerary()
  //   }
  // }

  private setDefaultItinerary() {
    if (this.defaultItinerary) {
      this.itineraryId = this.defaultItinerary.id
    } else if (this.itineraries.length > 0) {
      this.itineraryId = this.itineraries[0].id
    }
  }
}
