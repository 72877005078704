import Guid from '@/app/utils/guid'
import ItineraryLeg from './itinerary-leg'

export default class Itinerary {

  public id: string = Guid.empty()
  public tenantId: string = Guid.empty()
  public name: string = ''
  public legs: ItineraryLeg[] = []
  public origin: string = ''
  public destination: string = ''
  public remarks: string = ''
  public isDefault: boolean = false

  public isNew() {
    return this.id === Guid.empty()
  }
}
