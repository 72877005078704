import Invoice from '@/invoices/models/invoice'
import { InvoiceState } from '@/invoices/models/invoice-state'
import { PaymentMethod } from '@/invoices/models/payment-method'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class InvoiceBadge extends Vue {

  @Prop()
  private invoiceState!: InvoiceState

  @Prop()
  private paymentMethod!: PaymentMethod

  get invoiceText() {
    switch (this.invoiceState) {
      case InvoiceState.Pending:
        return 'offen'
      case InvoiceState.Paid:
        switch (this.paymentMethod) {
          case PaymentMethod.Cash:
            return 'Bargeld'
          case PaymentMethod.CreditCard:
            return 'Kreditkarte'
          case PaymentMethod.Invoice:
            return 'Rechnung'
          case PaymentMethod.DirectBilling:
            return 'Direktverr.'
          default:
            return 'bezahlt'
        }
      case InvoiceState.Canceled:
        return 'storniert'
      case InvoiceState.FirstDunning:
        return '1. Mahnung'
      case InvoiceState.SecondDunning:
        return '2. Mahnung'
      case InvoiceState.ThirdDunning:
        return '3. Mahnung'
      case InvoiceState.CreditNote:
        return 'Gutschrift'
      default:
        return 'unbekannt'
    }
  }

  get invoiceColor() {
    switch (this.invoiceState) {
      case InvoiceState.Pending:
        return '#FF4848'
      case InvoiceState.Paid:
        switch (this.paymentMethod) {
          case PaymentMethod.Cash:
            return 'primary'
          case PaymentMethod.CreditCard:
            return '#1A9115'
          case PaymentMethod.Invoice:
            return '#1A9115'
          default:
            return '#1A9115'
        }
      case InvoiceState.Canceled:
        return '#B0B1B3'
      case InvoiceState.FirstDunning:
        return '#FF4848'
      case InvoiceState.SecondDunning:
        return '#FF4848'
      case InvoiceState.ThirdDunning:
        return '#FF4848'
      default:
        return '#B0B1B3'
    }
  }
}
