import store from '@/app/store'
import ItinerariesModule from '@/itineraries/store/itineraries-module'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component
export default class ItinerariesMixin extends Vue {

  private itinerariesModule = getModule(ItinerariesModule, store)

  get itinerariesLoading() {
    return this.itinerariesModule.loading
  }

  get itineraries() {
    return this.itinerariesModule.itineraries
  }
}
