import PaginationRequest from "@/common/components/pagination/pagination-request";
import InvoicesDialog from "@/invoices/components/invoices-dialog/invoices-dialog";
import Invoice from "@/invoices/models/invoice";
import invoicesService from "@/invoices/services/invoices-service";
import reportsService from "@/reports/services/reports-service";
import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import InvoiceFilter from "@/invoices/models/invoice-filter";
import { InvoiceState } from "../models/invoice-state";
import { PaymentMethod } from "@/invoices/models/payment-method";
import Customer from "@/customers/entities/customer";
import moment from "moment";

@Component
export default class Invoices extends Vue {
  @Prop()
  private invoiceId!: string;

  @Ref()
  private dialog!: InvoicesDialog;

  private text: string = "";
  private invoiceState: InvoiceState | null = null;

  private invoices: Invoice[] = [];
  private loading: boolean = false;

  private filter = new InvoiceFilter();

  private pagination = new PaginationRequest();

  private numberFormatter = new Intl.NumberFormat("de", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });

  @Watch("text")
  private textChanged(val: string) {
    this.filter.text = val;
  }

  @Watch("invoiceState")
  private invoiceStateChanged(val: InvoiceState | null) {
    this.filter.status = val;
    this.fetchAll();
  }

  public async fetchAll() {
    this.loading = true;
    const response = await invoicesService.filterAllAsync(
      this.filter,
      this.pagination
    );
    this.invoices = response.items;
    this.pagination.totalItems = response.totalItems;
    this.loading = false;
  }

  public create() {
    this.dialog.create();
  }

  /**
   * exportList
   */
  public async exportList() {
    if (this.invoices.length === 0) {
      return;
    }
    let csvHeader =
      "data:text/csv;charset=utf-8,\uFEFFRechnung;Datum;Kunde;Status;Art;Betrag;Strecke/Kommentar";

      let csvContent = '\r\n';

    const response = await invoicesService.filterAllAsync(
      this.filter,
      new PaginationRequest(4096)
    );
    let invoices = response.items;

    invoices.forEach((invoice) => {
      var kind =
        invoice.rides.length === 1 ? "Einzelrechnung" : "Sammelrechnung";
      var paymentMethod = this.getPaymentMethod(invoice.paymentMethod);
      var invoiceState = this.getInvoiceState(invoice.invoiceState);
      var customer = this.getCustomer(invoice.customer);
      var invoiceAmount = invoice.amount ? invoice.amount : invoice.cost;

      csvContent +=
        invoice.invoiceNo +
        ";" +
        moment(invoice.date).format("YYYY-MM-DD HH:mm") +
        ";" +
        customer +
        ";" +
        invoiceState +
        ";" +
        paymentMethod +
        " " +
        kind +
        ";" +
        this.numberFormatter.format(invoiceAmount) +
        ";" +
        invoice.remarks.replace(";", ",").replace("\n", " ").replace("\r", " ") +
        "\r\n";
    });

      csvContent = csvHeader + encodeURIComponent(csvContent);
    const link = document.createElement("a");

      link.setAttribute("href", csvContent);
    link.setAttribute("download", "Rechnungen.csv");
    link.click();
  }

  private getCustomer(customer: Customer | null) {
    if (customer === null) {
      return "unbekannt";
    } else if (customer.firstName) {
      return customer.firstName + " " + customer.lastName;
    } else {
      return customer.company;
    }
  }

  private getInvoiceState(state: InvoiceState) {
    if (state === null) {
      return "n/a";
    }

    /*
    Pending = 0,
    Paid = 1,
    Canceled = 2,
    FirstDunning = 3,
    SecondDunning = 4,
    ThirdDunning = 5,
    CreditNote = 6,
    Settled = 7
    */
    let invStat: number = state;
    switch (invStat) {
      case 0:
        return "Offen";
      case 1:
        return "Bezahlt";
      case 2:
        return "Storniert";
      case 3:
        return "1.Mahnung";
      case 4:
        return "2.Mahnung";
      case 5:
        return "3.Mahnung";
      case 6:
        return "Gutschrift";
      case 7:
        return "Verrechnet";
    }
  }

  private getPaymentMethod(method: PaymentMethod | null) {
    // Cash = 0,
    // CreditCard = 1,
    // Invoice = 2,
    // DirectBilling = 3,

    if (method === null) {
      return "n/a";
    }

    let pm: number = method;

    switch (pm) {
      case 0:
        return "Barzahlung";

      case 1:
        return "Kreditkarte";

      case 2:
        return "Rechnung";

      case 3:
        return "Direktzahlung";

      default:
        return "n/a";
    }
  }

  public edit(invoice: Invoice) {
    this.dialog.edit(invoice);
  }

  public async save(invoice: Invoice) {
    await invoicesService.createAsync(invoice);
    await this.fetchAll();
  }

  public async update(invoice: Invoice) {
    await invoicesService.updateAsync(invoice);
    await this.fetchAll();
  }

  public async preview(invoice: Invoice) {
    this.$router.push({ name: "invoice", params: { invoiceId: invoice.id } });
  }

  public async createCreditNote(invoice: Invoice) {
    await invoicesService.createCreditNote(invoice);
    await this.fetchAll();
  }

  @Watch("pagination", { deep: true })
  private async paginationChanged(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      await this.fetchAll();
    }
  }

  private created() {
    this.pagination.sortBy = ["date"];
    this.pagination.sortDesc = [true];
  }

  private async mounted() {
    if (this.invoiceId) {
      const invoice = await invoicesService.findOneAsync(this.invoiceId);
      if (invoice) {
        this.edit(invoice);
      }
    }
  }
}
