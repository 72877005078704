import PaginationRequest from "@/common/components/pagination/pagination-request"
import XConfirmDialog from "@/common/components/x-confirm-dialog/x-confirm-dialog"
import CustomerDialog from "@/customers/components/customer-dialog/customer-dialog"
import Customer from "@/customers/entities/customer"
import customersService from "@/customers/services/customers-service"
import { DebouncedFunc } from "lodash"
import debounce from "lodash/debounce"
import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator"

@Component
export default class Customers extends Vue {
  @Ref()
  private confirmDialog!: XConfirmDialog

  @Ref()
  private dialog!: CustomerDialog

  private loading: boolean = false
  private customers: Customer[] = []

  private debounced: DebouncedFunc<() => Promise<void>> | null = null
  private text: string = ""
  private sorting: string = "company-asc"
  private pagination = new PaginationRequest(9)

  private sortItems = [
    { value: "company-asc", text: "Firma A - Z" },
    { value: "company-desc", text: "Firma Z - A" },
    { value: "lastname-asc", text: "Nachname A - Z" },
    { value: "lastname-desc", text: "Nachname Z - A" },
  ]

  @Watch("sorting")
  private sortingChanged(val: string) {
    switch (val) {
      case "company-asc":
        this.pagination.sortBy = ["company"]
        this.pagination.sortDesc = [false]
        break
      case "company-desc":
        this.pagination.sortBy = ["company"]
        this.pagination.sortDesc = [true]
        break
      case "lastname-asc":
        this.pagination.sortBy = ["lastname"]
        this.pagination.sortDesc = [false]
        break
      case "lastname-desc":
        this.pagination.sortBy = ["lastname"]
        this.pagination.sortDesc = [true]
        break
    }
    this.fetchAll()
  }

  private async clearAndFetchAll() {
    this.text = ""
    await this.fetchAll()
  }

  private async fetchAll() {
    this.loading = true
    const response = await customersService.filterAllAsync(
      this.text,
      this.pagination
    )
    this.customers = response.items
    this.pagination.totalItems = response.totalItems
    if (
      (this.pagination.page - 1) * this.pagination.itemsPerPage >
      this.pagination.totalItems
    ) {
      this.pagination.page = 1
    }
    this.loading = false
  }

  private create() {
    this.dialog.create()
  }

  private edit(customer: Customer) {
    this.dialog.edit(customer)
  }

  private async save(customer: Customer) {
    await customersService.createAsync(customer)
    await this.fetchAll()
  }

  private async update(customer: Customer) {
    await customersService.updateAsync(customer)
    await this.fetchAll()
  }

  private async remove(customer: Customer) {
    this.confirmDialog.open(customer)
  }

  private async removeConfirmed(customer: Customer) {
    await customersService.deleteAsync(customer.id)
    await this.fetchAll()
  }

  private async mounted() {
    this.pagination.sortBy = ["company"]
    this.pagination.sortDesc = [false]

    await this.fetchAll()
  }
}
