import axios from "@/app/axios";
import Responses from "@/app/utils/responses";
import PaginationRequest from "@/common/components/pagination/pagination-request";
import PaginationResponse from "@/common/components/pagination/pagination-response";
import CrudService from "@/common/services/crud-service";
import Invoice from "@/invoices/models/invoice";
import InvoiceFilter from "@/invoices/models/invoice-filter";

class InvoicesService extends CrudService<Invoice> {
  public async generatePdfAsync(id: string) {
    const url = `${this.resource}/${id}/pdf`;
    const pdf = await axios.get(url);
    return Responses.obj(pdf);
  }
  public async getPdfAsync(id: string) {
    const url = `${this.resource}/${id}/getpdf`;
    const pdf = await axios.get(url);
    return Responses.obj(pdf);
  }

  public async getPdf(id: string) {
    const url = `${this.resource}/getpdf2?id=${id}`;
    const pdf = await axios.get(url);
    return Responses.obj(pdf);
  }

  public async filterAllAsync(
    filter: InvoiceFilter,
    paging: PaginationRequest
  ) {
    const res = await axios.post<PaginationResponse<Invoice>>(this.resource, {
      filter: filter,
      paging: paging,
    });
    return Responses.pagedList(res);
  }
}

export default new InvoicesService("invoices");
