import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import TaxRate from '@/itineraries/models/tax-rate'

class TaxRatesService {

  public async fetchAll() {
    const res = await axios.get<TaxRate[]>('taxrates')
    return Responses.list(res)
  }
}

export default new TaxRatesService()
