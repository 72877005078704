import PagedMixin from '@/app/mixins/paged-mixin'
import Itinerary from '@/itineraries/models/itinerary'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ItinerariesTable extends Mixins(PagedMixin) {

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public itineraries!: Itinerary[]

  get headers() {
    return [
      { text: 'Standard', value: 'standard', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Startpunkt', value: 'origin' },
      { text: 'Zielpunkt', value: 'destination' },
      { text: 'Land', value: 'country', sortable: false },
      { text: 'Kommentar', value: 'remarks' },
    ]
  }

  private calculateForeignShare(itinerary: Itinerary) {
    return itinerary.legs
      .filter((l) => l.taxRate && l.taxRate.countryId !== 'AT')
      .reduce((sum, l) => sum + l.share, 0)
  }

  private onClick(itinerary: Itinerary) {
    this.$emit('edit', itinerary)
  }
}
