import store from "@/app/store"
import PaginationRequest from "@/common/components/pagination/pagination-request"
import ItineraryDialog from "@/itineraries/components/itinerary-dialog/itinerary-dialog"
import Itinerary from "@/itineraries/models/itinerary"
import TaxRate from "@/itineraries/models/tax-rate"
import itinerariesService from "@/itineraries/services/itineraries-service"
import taxRatesService from "@/itineraries/services/tax-rates-service"
import ItinerariesModule from "@/itineraries/store/itineraries-module"
import { Component, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import snacks from "@/app/services/snacks"
@Component
export default class Itineraries extends Vue {
  private itinerariesModule = getModule(ItinerariesModule, store)

  private dialog!: ItineraryDialog
  private loading: boolean = false
  private rates: TaxRate[] = []
  private itineraries: Itinerary[] = []

  private pagination = new PaginationRequest()

  public async fetchAll() {
    this.loading = true
    const response = await itinerariesService.findAllAsync(this.pagination)
    this.itineraries = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  public create() {
    this.dialog.create()
  }

  public edit(itinerary: Itinerary) {
    this.dialog.edit(itinerary)
  }

  public async save(itinerary: Itinerary) {
    // TODO: Think of a canonical approach to this issue.
    // Remove object references to tax rates.
    itinerary.legs.forEach((l) => (l.taxRate = null))

    await itinerariesService.createAsync(itinerary)
    await this.fetchAll()
    await this.itinerariesModule.fetchItineraries()
  }

  public async update(itinerary: Itinerary) {
    // Remove object references to tax rates.
    itinerary.legs.forEach((l) => (l.taxRate = null))

    await itinerariesService.updateAsync(itinerary)
    await this.fetchAll()
    await this.itinerariesModule.fetchItineraries()
  }

  public async delete_(itinerary: Itinerary) {
    try {
      if (confirm("Sind Sie sicher?")) {
        itinerariesService.deleteAsync(itinerary.id)
        await this.fetchAll()
      }
    } catch {
      snacks.error("Der Steuersatz konnte nicht gelöscht werden.")
    }
    this.fetchAll()
  }

  public async mounted() {
    this.dialog = this.$refs.dialog as ItineraryDialog
    let tmpRates = await taxRatesService.fetchAll()
    this.rates = tmpRates.map((r) => new TaxRate(r))
    this.rates.splice(0, 0, new TaxRate())
  }

  @Watch("pagination", { deep: true })
  private async paginationChanged(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }
}
