import Guid from "@/app/utils/guid";
import { InvoiceState } from "@/invoices/models/invoice-state";
import { PaymentMethod } from "@/invoices/models/payment-method";
import Ride from "@/rides/models/ride";
import moment from "moment";
import Customer from "@/customers/entities/customer";
import InvoiceStakes from "./invoice-stakes";

export default class Invoice {
  public id = Guid.empty();
  public tenantId = Guid.empty();
  public invoiceNo = "";
  public date = moment().format("YYYY-MM-DD HH:mm");
  public invoiceState = InvoiceState.Pending;
  public paymentMethod: PaymentMethod | null = PaymentMethod.Invoice;
  public rides: string[] = [];
  public ridesToCreate: Ride[] = [];
  public cost = 0;
  public amount: number | null = null;
  public reminderCharges: number | null = null;
  public customerId: string | null = null;
  public customer: Customer | null = null;
  public remarks = "";
  public isArchived = false;
  public isHidden = false;
  public stakes = new InvoiceStakes();

  public get isNew() {
    return this.id === Guid.empty();
  }
}
