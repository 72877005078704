import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import CrudService from '@/common/services/crud-service'
import Itinerary from '@/itineraries/models/itinerary'

class ItinerariesService extends CrudService<Itinerary> {

  public async search(text: string) {
    const res = await axios.get<Itinerary[]>(`${this.resource}/search`, {
      params: { text },
    })
    return Responses.list(res)
  }

  public async fetchAll() {
    const res = await axios.get<Itinerary[]>(`${this.resource}/list`)
    return Responses.list(res)
  }
}

export default new ItinerariesService('itineraries')
