import Guid from '@/app/utils/guid'
import Country from '@/locations/models/country'

export default class TaxRate {

  public id = Guid.empty()
  public country = new Country()
  public countryId = ''
  public rate = 0.0
  public description = ''

  constructor(other: TaxRate | null = null) {
    if(other != null) {
      Object.assign(this, other)
    }    
  }

  public get displayName() {
    if(this.description) {
      return this.country.name + ' ' + this.description
    }
    return this.country.name
  }
}
