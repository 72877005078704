import Period from '@/common/components/periods/period'
import ItinerariesMixin from '@/itineraries/mixins/itineraries-mixin'
import Ride from '@/rides/models/ride'
import tenantService from '@/tenants/services/tenants-service'
import ridesCalendarService from '@/rides/services/rides-calendar-service'
import ridesReportsService from '@/rides/services/rides-reports-service'
import ridesService from '@/rides/services/rides-service'
import moment from 'moment'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import Dates from '@/app/utils/dates'

@Component
export default class Dashboard extends Mixins(ItinerariesMixin) {

  private loading = false
  private loadingPrint = false
  private contractExpired = false
  private contractExpiringWithoutAutoRenew = false
  private contractExpiringWithAutoRenew = false
  private daysUntilContractExpiration = 0
  private membershipNumber = ""
  private driversRidesOnly = false
  private evaluationStart: (Date | null) = null
  private daysUntilEvaluationExpiration = 0

  private shift: Ride[] = []
  private open: Ride[] = []

  private periodShift: Period = new Period()
  private periodOpen: Period = new Period()

  @Watch('driversRidesOnly')
  private async onDriversRidesOnlyChanged() {
    this.loading = true
    this.fetchShift()
    this.loading = false
  }

  public async fetchAll() {
    this.loading = true
    await this.fetchShift()
    await this.fetchOpen()
    this.loading = false
  }

  private async fetchShift() {
    this.shift = await ridesCalendarService.findShiftAsync(this.periodShift, this.driversRidesOnly)
  }

  private async fetchOpen() {
    this.open = await ridesCalendarService.findOpenAsync(this.periodOpen)
  }

  private async print() {
    this.loadingPrint = true
    const start = moment(this.periodShift.start)
    const end = moment(this.periodShift.end)
    const pdfUrl = await ridesReportsService.generateRidesPdfAsync(start, end)
    window.open(pdfUrl)
    this.loadingPrint = false
  }

  private async gotoToday() {
    this.initPeriodShift()
    await this.fetchAll()
  }

  private async save(ride: Ride) {
    await ridesService.createAsync(ride)
    await this.fetchAll()
  }

  private async update(ride: Ride) {
    if (this.$auth.isDriver) {
      await ridesService.updateRemarksAsync(ride)
    } else {
      await ridesService.updateAsync(ride)
    }
    await this.fetchAll()
  }

  private async remove(ride: Ride) {
    await ridesService.deleteAsync(ride.id)
    await this.fetchAll()
  }

  private async enableContractAutoRenewal() {
    await tenantService.updateHasAutoRenewalContract(true)
    this.contractExpiringWithoutAutoRenew = false
    this.contractExpiringWithAutoRenew = true
  }

  private initPeriodShift() {
    this.periodShift.start = moment().startOf('day').toISOString(true)
    this.periodShift.end = moment().startOf('day').add(2, 'days').toISOString(true)
  }

  private async mounted() {
    const tenant = await tenantService.getCurrent()
    if (tenant) {
      this.evaluationStart = tenant.evaluationStart
      const evaluationEnd = moment(tenant.evaluationStart).add(1, 'months')
      this.daysUntilEvaluationExpiration = moment(evaluationEnd).diff(moment(), 'days')
    }
    this.initPeriodShift()
    await this.fetchAll()
  }

  private async created() {
    this.periodOpen.start = moment().subtract(1, 'years').format('YYYY-MM-DD')
    this.periodOpen.end = moment().format('YYYY-MM-DD')

    this.periodShift.timeIncluded = true
  }
}
