import Guid from '@/app/utils/guid'
import TaxRate from './tax-rate'

export default class ItineraryLeg {

  public id: string = Guid.empty()
  // public itineraryId: string = Guid.empty()
  public taxRateId: string = Guid.empty()
  public taxRate: TaxRate | null = null
  public share: number = 0
}
