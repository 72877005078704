import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import customersService from '@/customers/services/customers-service'
import CustomerCategory from '@/customers/entities/customer-category'

@Component
export default class Categories extends Vue {
    private categoryHeaders = [
        { text: 'Name', value: 'title' },
        { text: '', value: 'action', sortable: false, align: 'right' },
    ]
    private categoriesLoading = false
    private categoryDialog = false
    private categories: CustomerCategory[] = []
    private editedCategoryIndex = -1
    private editedCategory: CustomerCategory = new CustomerCategory

    private snackbarText = 'Erfolgreich gespeichert.'
    private snackbarOpened = false

    private async mounted() {        
        this.fetchAllCategories()        
    }

    private create() {
        this.categoryDialog = true;
    }

    private get customerCategoryFormTitle(): string {
        return this.editedCategoryIndex === -1 ? 'Neue Kategorie' : 'Kategorie bearbeiten'
    }

    private async fetchAllCategories() {
        this.categoriesLoading = true
        const response = await customersService.findAllCategoriesAsync()
        this.categories = response
        this.categoriesLoading = false
    }

    private editCategory(category: CustomerCategory) {
        this.editedCategoryIndex = this.categories.indexOf(category)
        this.editedCategory = Object.assign({}, category)
        this.categoryDialog = true
    }

    private async deleteCategory(category: CustomerCategory) {
        const index = this.categories.indexOf(category)
        if (confirm('Möchten Sie die Kategorie wirklich löschen?')) {
            await customersService.removeCategoryAsync(category.id)
            this.fetchAllCategories()
        }
    }

    private closeEditCategory() {
        this.categoryDialog = false
        this.editedCategoryIndex = -1
        this.editedCategory = new CustomerCategory()
    }

    private async saveEditCategory() {
        if (this.editedCategoryIndex > -1) {
            await customersService.updateCategoryAsync(this.editedCategory)
        } else {
            await customersService.saveCategoryAsync(this.editedCategory)
        }
        this.fetchAllCategories()
        this.closeEditCategory()
    }
}