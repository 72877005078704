export const enum InvoiceState {
  Pending = 0,
  Paid = 1,
  Canceled = 2,
  FirstDunning = 3,
  SecondDunning = 4,
  ThirdDunning = 5,
  CreditNote = 6,
  Settled = 7,
}
