import PagedMixin from "@/app/mixins/paged-mixin";
import Invoice from "@/invoices/models/invoice";
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class InvoicesTable extends Mixins(PagedMixin) {
  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  @Prop({ default: () => [] })
  public invoices!: Invoice[];

  @Watch("invoices")
  private invoicesChanged() {
    if (
      this.pPagination.totalItems <
      (this.pPagination.page - 1) * this.pPagination.itemsPerPage
    ) {
      this.pPagination.page = 1;
    }
  }

  get headers() {
    return [
      { text: "Rechnung", value: "invoiceNo" },
      { text: "Datum", value: "date" },
      { text: "Kunde", value: "customer" },
      { text: "Status", value: "state" },
      { text: "Art", value: "kind", sortable: false },
      { text: "Betrag", value: "cost", sortable: false, align: "right" },
      { text: "Strecke / Kommentar", value: "remarks" },
    ];
  }

  private onClick(invoice: Invoice) {
    this.$emit("edit", invoice);
  }
}
