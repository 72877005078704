





import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator"
import { InvoiceState } from "../models/invoice-state"

@Component
export default class InvoiceStateSelect extends Vue {
  @Prop()
  public value: InvoiceState | null = null

  public status: InvoiceState | null = null

  public items = [
    { name: "(Status)", value: null },
    { name: "offen", value: InvoiceState.Pending },
    { name: "bezahlt", value: InvoiceState.Paid },
    { name: "storniert", value: InvoiceState.Canceled },
    { name: "1. Mahnung", value: InvoiceState.FirstDunning },
    { name: "2. Mahnung", value: InvoiceState.SecondDunning },
    { name: "3. Mahnung", value: InvoiceState.ThirdDunning },
    { name: "Gutschrift", value: InvoiceState.CreditNote },
  ]

  private change() {
      this.$emit('input', this.status)
  }

  private mounted() {
      this.status = this.value
  }
}
