import store from '@/app/store'
import Itinerary from '@/itineraries/models/itinerary'
import TaxRate from '@/itineraries/models/tax-rate'
import itinerariesService from '@/itineraries/services/itineraries-service'
import taxRatesService from '@/itineraries/services/tax-rates-service'
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'

@Module({ store, name: 'ItinerariesModule' })
export default class ItinerariesModule extends VuexModule {

  private _loading = false
  private _itineraries: Itinerary[] = []

  private _rates: TaxRate[] = []

  get loading() {
    return this._loading
  }

  get itineraries() {
    return this._itineraries
  }

  get rates() {
    return this._rates
  }

  @Mutation
  public setLoading(loading: boolean) {
    this._loading = loading
  }

  @Mutation
  public setItineraries(itineraries: Itinerary[]) {
    this._itineraries = itineraries
  }

  @Mutation
  public setRates(rates: TaxRate[]) {
    this._rates = rates
  }

  @Action
  public async fetchItineraries() {
    this.context.commit('setLoading', true)
    const itineraries = await itinerariesService.fetchAll()
    this.context.commit('setItineraries', itineraries)
    this.context.commit('setLoading', false)
  }

  @Action
  public async fetchAll() {
    this.context.commit('setLoading', true)
    const rates = await taxRatesService.fetchAll()
    const itineraries = await itinerariesService.fetchAll()
    this.context.commit('setRates', rates)
    this.context.commit('setItineraries', itineraries)
    this.context.commit('setLoading', false)
  }
}
